import React from 'react';
import { NavMenu } from './NavMenu';
import { Container } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import Footer from './Footer';

const Layout = ({ children }) => {
  const { isLoading, error } = useAuth0();

  return (
    <div className="d-flex flex-column min-vh-100">
      <NavMenu />
      <Container>
        {
          isLoading ? (<>Laddar sidan...</>)
            : error ? (<>Ooops... {error.message}</>)
              : children
        }
      </Container>
      <Footer />
    </div>
  );
}

export default Layout;