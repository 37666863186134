import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import useRequest from '../../api/Api';
import CustomerService from '../../api/services/CustomerService';
import UnitService from '../../api/services/UnitService';
import UpdateUnitForm from '../../components/unit/UpdateUnitForm';

const UnitEdit = () => {
  const { id } = useParams()
  const request = useRequest();
  const history = useHistory();

  const [unit, setUnit] = useState();

  useEffect(() => {
    const getUnit = async () => {
      const u = await fetchUnit(id);
      if (u)
        setUnit(u);
    }

    getUnit();
  }, [])

  const fetchUnit = async (id) => {
    return await request(UnitService.getById(id));
  }

  const createCutomser = async (customer) => {
    return await request(CustomerService.create(customer));
  }

  const updateCustomer = async (id, customer) => {
    return await request(CustomerService.update(id, customer));
  }

  const updateUnit = async (newUnit) => {
    let customerId = newUnit.customer.id;

    // If new customer was selected id will be null
    // Create new customer if id is null else update existing customer
    if (customerId === null) {
      const newCustomer = await createCutomser(newUnit.customer);
      if (newCustomer)
        customerId = newCustomer.id;
    } else {
      const updCustomer = await updateCustomer(customerId, newUnit.customer);
      if (updCustomer)
        customerId = updCustomer.id;
    }

    const updUnit = { ...newUnit, customerId: customerId };
    await request(UnitService.update(id, updUnit));

    history.goBack();
  }

  // Searchbar
  const handleSearchOptions = async (inputValue) => {
    return request(CustomerService.getByName(inputValue)).then(c => c.items);
  };

  return (
    <div>
      {unit && (
        <>
          <h2>Uppdatera enhetens uppgifter</h2>
          <UpdateUnitForm unitToUpdate={unit} onUpdate={updateUnit} onSearch={handleSearchOptions} />
        </>
      )}
    </div>
  )
}

export default UnitEdit;
