import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Button } from 'reactstrap'

const UpdateInstallerForm = ({ onUpdate, installerToUpdate }) => {
  const initialInstaller = {
    name: installerToUpdate?.name || '',
    email: installerToUpdate?.email || '',
    phone: installerToUpdate?.phone || '',
  };

  const [installer, setInstaller] = useState(initialInstaller);

  const onSubmit = (e) => {
    e.preventDefault();
    onUpdate(installer);
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setInstaller({ ...installer, [name]: value });
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="name">Namn</Label>
        <Input type="text" name="name" id="name" onChange={onChange} value={installer.name} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="email">E-post</Label>
        <Input type="email" name="email" id="email" onChange={onChange} value={installer.email} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="phone">Telefon</Label>
        <Input type="text" name="phone" id="phone" onChange={onChange} value={installer.phone} required />
      </FormGroup>
      <Button color="primary">Spara</Button>
    </Form>
  )
}

export default UpdateInstallerForm;
