import React from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { RegNumberInput } from './RegNumberInput';

export function AddUnitForm({ unit, liquidPartId, onChange, onSubmit, children }) {
  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="id">Elektronikbox</Label>
        <Input type="text" name="id" id="id" value={unit.id} disabled required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="liquidPartId">Vätskebehållare</Label>
        <Input type="text" name="liquidPartId" id="liquidPartId" value={liquidPartId} disabled required />
      </FormGroup>
      <RegNumberInput value={unit.carRegNo} onChange={onChange} />
      {/* <FormGroup>
        <Label htmlFor="carMileageAtInstall">Miltal fordon</Label>
        <Input type="number" name="carMileageAtInstall" id="carMileageAtInstall" onChange={onChange} value={unit.carMileageAtInstall} />
      </FormGroup> */}
      {/* <FormGroup>
        <Label htmlFor="mileagePerYear">Årlig körsträcka (mil)</Label>
        <Input type="number" name="mileagePerYear" id="mileagePerYear" onChange={onChange} value={unit.mileagePerYear} />
      </FormGroup> */}
      {children}
      <Button color="primary">Nästa</Button>
    </Form>
  );
}