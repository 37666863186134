import React, { useEffect, useState } from 'react';
import { useDebounce } from 'react-use';
import useRequest from '../../api/Api';
import CustomerService from '../../api/services/CustomerService';
import UnitService from '../../api/services/UnitService';
import InstallerService from '../../api/services/InstallerService';
import { QrScanner } from '../../components/common/QrScanner';
import { ValidateForm } from '../../components/unit/ValidateForm';
import { AddUnitForm } from '../../components/unit/AddUnitForm';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import { UnitSuccess } from '../../components/unit/UnitSuccess';
import IdGenerator from '../../components/common/IdGenerator';
import QrGenerator from '../../components/common/QrGenerator';
import Search from '../../components/customer/Search';
import { useAuth0 } from '@auth0/auth0-react';
import LiquidPartService from '../../api/services/LiquidPartService';

export function AddUnit() {
  const { user } = useAuth0();
  const request = useRequest();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileageAtInstall': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const initialCustomer = {
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  const [unit, setUnit] = useState(initialUnit);
  const [unitExists, setUnitExists] = useState(false);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState(initialCustomer);
  const [customerModified, setCustomerModified] = useState(false);
  const [liquidPartId, setliquidPartId] = useState('');

  const [debouncedUnitId, setDebouncedUnitId] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedUnitId(unit.id);
    },
    500,
    [unit.id]
  );

  useEffect(() => {
    if (unit?.id && unit.id?.length === 36)
      validateUnit();
  }, [debouncedUnitId]);

  const [debouncedliquidPartId, setDebouncedliquidPartId] = useState('');
  const [, cancelb] = useDebounce(
    () => {
      setDebouncedliquidPartId(liquidPartId);
    },
    500,
    [liquidPartId]
  );

  useEffect(() => {
    if (liquidPartId && liquidPartId.length === 36)
      validateliquidPart();
  }, [debouncedliquidPartId]);


  const next = () => setStep(step + 1);

  // Add unit
  const addUnit = async (installerId, customerId) => {
    const newUnit = { ...unit, installerId: installerId, customerId: customerId };
    return await request(UnitService.create(newUnit));
  }

  // Fetch installer
  const getInstaller = async () => {
    return await request(InstallerService.getByProviderId(user.sub));
  }

  // Add installer
  const addInstaller = async () => {
    return await request(InstallerService.create({ providerUserId: user.sub, name: user.nickname, email: user.email }));
  }

  // Update customer
  const updateCustomer = async () => {
    if (customer.id) {
      if (customerModified)
        return await request(CustomerService.update(customer.id, customer));

      return customer;
    }
  }

  // Add customer
  const addCustomer = async () => {
    return await request(CustomerService.create(customer));
  }

  // Check if unit exists, if not proceed to the next step
  const validateUnit = async () => {
    const validatedUnit = await request(UnitService.validate(unit.id));
    validatedUnit
      ? setUnitExists(validatedUnit)
      : next();
  }

  // Handle forms/components

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });
    if (unitExists)
      setUnitExists(!unitExists);
  }

  const handleUnitIdChange = (id) => {
    setUnit({ ...unit, id: id });
  }

  const handleAddUnitSubmit = async (e) => {
    e.preventDefault();

    if (user === null)
      return;

    // Get or Add installer 
    let installer = await getInstaller();
    if (!installer)
      installer = await addInstaller();

    // Update or Add customer
    let customer = await updateCustomer();
    if (!customer)
      customer = await addCustomer();

    const newUnit = await addUnit(installer.id, customer.id);
    if (!newUnit)
      return; // send to errorpage?

    const newliquidPart = await createliquidPart(newUnit.id, installer.id);

    if (newUnit && newliquidPart)
      next();
  }

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });

    if (!customerModified)
      setCustomerModified(true);
  }

  // Searchbar
  const handleSearchOptions = async (inputValue) => {
    return request(CustomerService.getByName(inputValue)).then(c => c.items);
  };

  const createliquidPart = async (unitId, installerId) => {
    return await request(LiquidPartService.create({ id: liquidPartId, unitId: unitId, installerId: installerId }));
  }

  const validateliquidPart = async () => {
    const validated = await request(LiquidPartService.getById(liquidPartId)).catch(() => (false));
    validated
      ? setUnitExists(true)
      : next();
  }

  const handleChangeliquidPartId = (e) => {
    const { name, value } = e.target;
    setliquidPartId(value);
    if (unitExists)
      setUnitExists(!unitExists);
  }

  return (
    <div>
      <h2>Registrera enhet</h2>
      {step === 1 && (
        <div>
          <h4>Elektronikbox</h4>
          <p>Skanna QR-koden eller fyll i id för elektronikbox som ska registreras</p>
          <QrScanner onScan={handleUnitIdChange} />
          <div className="my-3">
            <IdGenerator onGenerate={handleUnitIdChange} />
          </div>
          <ValidateForm id={unit.id} onChange={handleChange} onValidate={validateUnit} invalid={unitExists} feedback={unitExists && 'Enheten finns redan'} />
        </div>
      )}
      {step === 2 && (
        <div>
          <h4>Vätskebehållare</h4>
          <p>Skanna QR-koden eller fyll i id för vätskebehållare som ska registreras</p>
          <QrScanner onScan={(id) => setliquidPartId(id)} />
          <div className="my-3">
            <IdGenerator onGenerate={(id) => setliquidPartId(id)} />
          </div>
          <ValidateForm id={liquidPartId} onChange={handleChangeliquidPartId} onValidate={validateliquidPart} invalid={unitExists} feedback={unitExists && 'Enheten finns redan'} />
        </div>
      )}
      {step === 3 && (
        <div>
          <p>Fyll i uppgifter om fordonet.</p>
          <AddUnitForm unit={unit} liquidPartId={liquidPartId} onChange={handleChange} onSubmit={handleAddUnitSubmit}>
            <hr className="mt-5 mb-4" />
            <h5>Kunduppgifter</h5>
            <p className="mb-4">Fyll i kunduppgifterna eller sök efter en kund.</p>
            <Search
              initialValue={initialCustomer}
              selectedValue={customer}
              onSelect={(c) => setCustomer(c)}
              loadOptions={handleSearchOptions}
              className="mb-3"
            />
            <CustomerFormFields customer={customer} onChange={handleCustomerChange} />
          </AddUnitForm>
        </div>
      )}
      {step === 4 && (
        <div>
          <UnitSuccess />
          <QrGenerator value={unit.id} />
        </div>
      )}
    </div>
  )
}