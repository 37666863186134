import React from 'react';
import { useHistory } from 'react-router';
import { Form, Button, ButtonGroup } from 'reactstrap';
import { CustomerFormFields } from './CustomerFormFields';

const CustomerForm = ({ onSubmit, customer, onChange, disableAll, buttonText, useBackButton }) => {
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  }

  return (
    <Form onSubmit={handleSubmit}>
      <CustomerFormFields customer={customer} onChange={onChange} disableAll={disableAll} />
      <ButtonGroup>
        <Button color="primary">{buttonText || 'Nästa'}</Button>
        {useBackButton &&
          <Button onClick={() => { history.goBack(); }}>Tillbaka</Button>
        }
      </ButtonGroup>
    </Form>
  )
}

export default CustomerForm;