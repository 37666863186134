const url = '/unit';

const getList = (pageNumber = 1, pageSize = 10, orderBy = '') => ({
  method: 'get',
  url: `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}${orderBy ? `&orderBy=${orderBy}` : ''}`
});

const getById = (id) => ({ method: 'get', url: `${url}/${id}` });

const create = (unit) => ({ method: 'post', url: url, data: unit });

const update = (id, unit) => ({ method: 'put', url: `${url}/${id}`, data: unit });

const disable = (id) => ({ method: 'delete', url: `${url}/${id}/disable` });

const validate = (id) => ({ method: 'get', url: `${url}/${id}/validate` })

const getByIdNoAuth = (id) => ({ method: 'get', url: `${url}/noauth/${id}` });

const getByEmailNoAuth = (id, customerEmail) => ({ method: 'get', url: `${url}/noauth/${id}/${customerEmail}` });

const updateVehicleMileage = (id, unit) => ({ method: 'patch', url: `${url}/noauth/${id}`, data: unit });


export default {
  getList,
  getById,
  create,
  update,
  disable,
  validate,
  getByIdNoAuth,
  getByEmailNoAuth,
  updateVehicleMileage,
};