import React from 'react'

const Footer = () => {
  return (
    <div className="mt-auto mb-4" >
      <hr />
      <div className="container">
        <address>EmiNique AB | Slottsmöllan G | 302 31 Halmstad | <a className="text-dark" href="mailto:info@eminique.se">info@eminique.se</a> | <a className="text-dark" href="tel:035-123 456">035-123 456</a></address>
      </div>
    </div>
  )
}

export default Footer;
