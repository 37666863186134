import React from 'react';
import { Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';


export default function IdGenerator({ onGenerate }) {
  const handleClick = () => {
    onGenerate(uuidv4());
  }

  return (<Button onClick={handleClick}>Skapa nytt id</Button>)
}