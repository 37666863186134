import React, { useState, useEffect } from 'react'
import useRequest from '../../api/Api';
import InstallerService from '../../api/services/InstallerService';

import { Table, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-responsive-pagination';

const tableHeaders = [
  { id: 'name', name: 'Namn' },
  { id: 'email', name: 'E-post' },
  { id: 'phone', name: 'Tele' },
  { id: 'edit', name: '' },
  { id: 'block', name: '' },
]

const InstallerList = () => {
  const request = useRequest();
  const history = useHistory();

  const [installers, setInstallers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const getInstallers = async () => {
      setLoading(true);

      await syncInstallers();
      const data = await fetchInstallers(currentPage);

      setInstallers(data.items);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);

      setLoading(false);
    }

    getInstallers();

  }, [currentPage])

  const fetchInstallers = async (pageNumber) => {
    return await request(InstallerService.getAll(pageNumber));
  }

  const syncInstallers = async () => {
    await request(InstallerService.sync());
  }

  const toggleBlock = async (id) => {
    const installer = await request(InstallerService.toggleBlocked(id));
    setInstallers(installers.map((inst =>
      inst.id === installer.id ? { ...inst, blocked: installer.blocked } : inst
    )));
  }

  // Pagination page click
  const pageClick = (page) => {
    if (!loading)
      setCurrentPage(page);
  }

  return (
    <div>
      <h2>Montörer</h2>
      <Button color="primary" style={{ margin: '1rem 0' }} onClick={() =>
        history.push('/installers/add')}>Lägg till</Button>
      <Table responsive striped >
        <thead>
          <tr>
            {tableHeaders && tableHeaders.map(header => (
              <th key={header.id} id={header.id} >
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>

          {installers && installers.map(installer => (
            <tr key={installer.id}>
              <td>{installer.name}</td>
              <td>{installer.email}</td>
              <td>{installer.phone}</td>
              <td>
                <Button outline color="primary" size="sm" style={{ marginTop: '-0.3rem' }} onClick={() =>
                  history.push({ pathname: `/installers/edit/${installer.id}`, state: { id: installer.id } })}>Ändra</Button>
              </td>
              <td>
                <Button outline color={installer.blocked ? "success" : "danger"} size="sm" style={{ marginTop: '-0.3rem' }} onClick={() =>
                  toggleBlock(installer.id)}>
                  {installer.blocked ? (
                    "Avblockera"
                  ) :
                    "Blockera"
                  }
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div>
        <Pagination
          current={currentPage}
          total={totalPages}
          onPageChange={pageClick}
        />
      </div>
    </div >
  )
}

export default InstallerList;
