import React, { useEffect, useState } from 'react'
import { UnitForm } from '../../components/unit/UnitForm';
import { ValidateForm } from '../../components/unit/ValidateForm';
import { QrScanner } from '../../components/common/QrScanner';
import UnitService from '../../api/services/UnitService';
import useRequest from '../../api/Api';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import LiquidPartService from '../../api/services/LiquidPartService';
import InstallerService from '../../api/services/InstallerService';
import { useAuth0 } from '@auth0/auth0-react';
import { useDebounce } from 'react-use';

export default function DisableUnit() {
  const request = useRequest();
  const { user } = useAuth0();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileage': 0,
    'mileageTravelled': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const [unit, setUnit] = useState(initialUnit);
  const [isActive, setIsActive] = useState(true);
  const [unitExists, setUnitExists] = useState(true);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState({ name: '', email: '', phone: '', address: '' });

  const [debouncedUnitId, setDebouncedUnitId] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedUnitId(unit.id);
    },
    500,
    [unit.id]
  );

  useEffect(() => {
    if (unit?.id && unit.id?.length === 36)
      validateUnit();
  }, [debouncedUnitId]);


  const next = () => setStep(step + 1);

  // Disable unit
  const disableUnit = async () => {
    return await request(UnitService.disable(unit.id));
  }

  const disableLiquidPart = async (id, installerId) => {
    return await request(LiquidPartService.remove(id, { id: id, installerId: installerId }));
  }

  const getInstaller = async () => {
    return await request(InstallerService.getByProviderId(user.sub));
  }

  const handleScan = (result) => {
    setUnit({ ...unit, id: result });
    // validateUnit();
  }

  const validateUnit = async () => {
    const validUnit = await request(UnitService.getById(unit.id))
      .catch(() => setUnitExists(false));

    if (validUnit) {
      setUnit(validUnit);

      if (validUnit.customer)
        setCustomer(validUnit.customer);

      setIsActive(validUnit.active);
      if (validUnit.active)
        next();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });

    if (!isActive)
      setIsActive(!isActive);
    if (!unitExists)
      setUnitExists(!unitExists);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const unitDisabled = await disableUnit();
    if (unitDisabled) {
      console.log(`Disabled ${unitDisabled.id}`);
      if (unit.liquidPart?.id !== null) {
        const installer = await getInstaller();
        await disableLiquidPart(unit.liquidPart.id, installer?.id)
      }
      next();
    }
  }

  return (
    <div>
      <h2>Släck enhet</h2>
      {step === 1 && (
        <div>
          <p>Skanna QR-koden eller fyll i id för enheten som ska släckas</p>
          <QrScanner onScan={handleScan} />
          <ValidateForm className="mt-4" id={unit.id} onChange={handleChange} onValidate={validateUnit} invalid={!isActive || !unitExists} feedback={!isActive && 'Enheten är redan släckt' || !unitExists && 'Enheten finns ej'} />
        </div>
      )}
      {step === 2 && (
        <div>
          <p>Kontrollera att uppgifterna stämmer.</p>
          <UnitForm unit={unit} onChange={handleChange} onSubmit={handleSubmit} buttonContent='Släck enhet' buttonColor='danger' disableAll={true} >
            <hr />
            <h5>Kunduppgifter</h5>
            <CustomerFormFields customer={customer} disableAll={true} />
          </UnitForm>
        </div>
      )}
      {step === 3 && (
        <div>
          <p>Enheten är nu släckt</p>
        </div>
      )}
    </div>
  );
}
