import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'reactstrap';
import config from '../../config';


export default function QrGenerator({ value }) {
  const qrValue = config.QR_URL + value;

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  return (
    <div>

      <div ref={componentRef}>
        <QRCode value={qrValue} />
      </div>
      <p>{qrValue}</p>
      <Button onClick={handlePrint}>Skriv ut QR-kod</Button>
    </div >)
}