const url = '/customer';

const getAll = (pageNumber = 1, pageSize = 10) => ({ method: 'get', url: `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}` });

const getById = (id) => ({ method: 'get', url: `${url}/${id}` });

const getByName = (name) => ({ method: 'get', url: `${url}?name=${name}` });

const create = (customer) => ({ method: 'post', url: url, data: customer });

const update = (id, customer) => ({ method: 'put', url: `${url}/${id}`, data: customer });

const deleteById = (id) => ({ method: 'delete', url: `${url}/${id}` });


export default {
  getAll,
  getById,
  getByName,
  create,
  update,
  deleteById,
};