const env = process.env;
const config = {
  API_URL: env.REACT_APP_API_URL,
  QR_URL: env.REACT_APP_QR_URL,
  AUTH_DOMAIN: env.REACT_APP_AUTH_DOMAIN,
  AUTH_CLIENT_ID: env.REACT_APP_AUTH_CLIENT_ID,
  AUTH_AUDIENCE: env.REACT_APP_AUTH_AUDIENCE,
  AUTH_ROLES: env.REACT_APP_AUTH_ROLES,
};

export default config;