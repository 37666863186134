import React, { useEffect, useState } from 'react';
import useRequest from '../api/Api';
import UnitService from '../api/services/UnitService';
import StatsService from '../api/services/StatsService';
import { Table, CardDeck } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
import DashboardCard from '../components/dashboard/DashboardCard';

const chartOptions = {
  aspectRatio: 2.4,
  maxBarThickness: 90,
  plugins: {
    datalabels: {
      display: true,
      color: 'black',
      align: 'end',
      anchor: 'end',
      font: { size: '14' },
    },
    legend: {
      display: false,
    },
    // title: {
    //   display: true,
    //   text: 'Monterade enheter per månad',
    //   font: {
    //     size: 20,
    //   },
    // },
  },
  scales: {
    x: {
      grid: {
        // display: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      grace: "25%",
      grid: {
        // display: false,
        drawOnChartArea: false,
        drawTicks: false,
        borderWidth: 2,
      },
      ticks: {
        beginAtZero: true,
        display: false,
      },
    },
  },
};

const tableHeaders = [
  { id: 'installDate', value: 'Installations datum' },
  { id: 'installer.name', value: 'Installatör' },
  { id: 'customer.name', value: 'Kund' },
  { id: 'carRegNo', value: 'Reg.nummer' },
  { id: 'mileageTravelled', value: 'Mil sedan inst.' },
];

const dashboardColors = [
  'rgba(38, 70, 83, 1)',
  'rgba(42, 157, 143, 1)',
  // 'rgba(233, 196, 106, 1)',
  'rgba(244, 162, 97, 1)',
  // 'rgba(231, 111, 81, 1)'
];

const CurrentYear = () => {
  return new Date().getFullYear();
}

const Dashboard = () => {
  const request = useRequest();

  const [unitStats, setUnitStats] = useState();
  const [unitStatsChart, setUnitStatsChart] = useState({});
  const [lastInstalledUnits, setLastInstalledUnits] = useState([]);

  useEffect(() => {
    // Fetch unit stats and extract the chart data
    const getUnitStats = async () => {
      const stats = await fetchUnitStats();
      if (stats)
        setUnitStats(stats);

      // Barchart
      let labels = [];
      let data = [];

      if (stats.months)
        stats.months.forEach(u => {
          if (u.year === CurrentYear()) {
            labels.push(u.month);
            data.push(u.total);
          }
        })

      const chartData = getChartData('Monterade enheter per månad', labels, data);
      setUnitStatsChart(chartData);
    }

    // Get last 5 installed units
    const getLastInstalledUnits = async () => {
      const data = await fetchUnits(1, 5, "installDate desc");
      setLastInstalledUnits(data.items);
    }

    getUnitStats();
    getLastInstalledUnits();
  }, [])

  // Get stats
  const fetchUnitStats = async () => {
    return await request(StatsService.unit());
  }

  // Get units
  const fetchUnits = async (pageNumber, pageSize, orderBy) => {
    return await request(UnitService.getList(pageNumber, pageSize, orderBy));
  }

  const getChartData = (label, labels, data) => {
    return {
      labels: labels,
      datasets: [
        {
          label: label,
          data: data,
          backgroundColor: [...dashboardColors],
        }
      ],
    }
  }


  return (
    <div>
      <div style={{ marginBottom: '2rem' }}>
        <CardDeck>
          <DashboardCard inverse={true} title={unitStats?.total || 0} text={"Antal installerade enheter"} color={dashboardColors[0]} />
          <DashboardCard inverse={true} title={unitStats?.status.active || 0} text={"Aktiva enheter"} color={dashboardColors[0]} />
          {/* <DashboardCard title={} text={} color={dashboardColors[0]} /> */}
        </CardDeck>

        <CardDeck>
          <DashboardCard
            inverse={true}
            title={
              unitStats?.months.length > 0
                ? unitStats.months.reduce((n, m) => m.year === CurrentYear() ? n + m.total : n, 0)
                : 0
            }
            text={"Installerade enheter i år"}
            color={dashboardColors[0]}
          />
          <DashboardCard
            inverse={true}
            title={
              unitStats?.months.length > 0
                ? unitStats.months[unitStats.months.length - 1].total
                : 0
            }
            text={"Installerade enheter senaste månaden"}
            color={dashboardColors[0]}
          />
        </CardDeck>
      </div>
      {
        // unitStats?.months.length > 0 && ()
        Object.keys(unitStatsChart).length > 0 &&
        <div style={{ margin: '5rem 0' }}>
          <h3>Monterade enheter per månad</h3>
          <Bar data={unitStatsChart} options={chartOptions} plugins={[ChartDataLabels]} /* height={135} */ />
        </div>
      }
      {
        lastInstalledUnits.length > 0 &&
        <div>
          <h3>Senaste installationerna</h3>
          <Table responsive striped>
            <thead>
              <tr>
                {tableHeaders && tableHeaders.map(header => (
                  <th key={header.id} id={header.id} >
                    {header.value}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {lastInstalledUnits.map(unit => (
                <tr key={unit.id}>
                  <td>{format(new Date(unit.installDate), 'yyyy-MM-dd HH:mm', { locale: sv })}</td>
                  <td>{unit.installer?.name}</td>
                  <td>{unit.customer?.name}</td>
                  <td>{unit.carRegNo}</td>
                  <td>{unit.mileageTravelled} mil</td>
                </tr>
              ))}
            </tbody>
          </Table >
        </div>
      }
    </div >
  )
}

export default Dashboard
