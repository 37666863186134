import React from 'react';
import './custom.scss'
import { Switch, Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { WithRoleBasedRedirect } from './auth/WithRoleBasedRedirect';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Unit from './pages/unit/Unit';
import { ListUnits } from './pages/unit/ListUnits';
import { AddUnit } from './pages/unit/AddUnit';
import { ReplaceUnit } from './pages/unit/ReplaceUnit';
import DisableUnit from './pages/unit/DisableUnit';
import UnitEdit from './pages/unit/UnitEdit';
import CustomerList from './pages/customer/CustomerList';
import CustomerEdit from './pages/customer/CustomerEdit';
import InstallerList from './pages/installer/InstallerList';
import InstallerEdit from './pages/installer/InstallerEdit';
import InstallerAdd from './pages/installer/InstallerAdd';
import NotFound from './pages/NotFound';

const ProtectedRoute = ({ component, roles, ...args }) => (
  roles ? (
    <Route component={withAuthenticationRequired(() => <WithRoleBasedRedirect Component={component} options={{ roles }} />)} {...args} />
  ) : (
    <Route component={withAuthenticationRequired(component)} {...args} />
  )
);


export default function App() {
  return (
    <Switch>
      <Route exact path='/unit/:id' component={Unit} />
      <Layout>
        <ProtectedRoute exact path={['/', '/dashboard']} component={Dashboard} roles={["admin", "installer"]} />

        <ProtectedRoute path='/units' component={ListUnits} roles={["admin", "installer"]} />
        <ProtectedRoute path='/add' component={AddUnit} roles={["admin", "installer"]} />
        <ProtectedRoute path='/replace' component={ReplaceUnit} roles={["admin", "installer"]} />
        <ProtectedRoute path='/disable' component={DisableUnit} roles={["admin", "installer"]} />
        <ProtectedRoute path='/edit/:id' component={UnitEdit} roles={["admin", "installer"]} />

        <ProtectedRoute exact path='/customers' component={CustomerList} roles={"admin"} />
        <ProtectedRoute path='/customers/edit/:id' component={CustomerEdit} roles={"admin"} />

        <ProtectedRoute exact path='/installers' component={InstallerList} roles={"admin"} />
        <ProtectedRoute path='/installers/edit/:id' component={InstallerEdit} roles={"admin"} />
        <ProtectedRoute path='/installers/add' component={InstallerAdd} roles={"admin"} />
      </Layout>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}
