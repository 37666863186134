import React from 'react';
import { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import config from '../config';

const roleClaimType = config.AUTH_ROLES;

export const WithRoleBasedRedirect = ({ Component, options }) => {
  const history = useHistory();
  const { getIdTokenClaims } = useAuth0();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    const getRoles = async () => {
      const claims = await getIdTokenClaims();
      return claims ? claims[roleClaimType] || [] : [];
    }

    const checkRoles = async (roles) => {
      const userRoles = await getRoles();
      const isAuthorized = userRoles.some(r => roles.includes(r))

      if (isAuthorized)
        setIsAuthorized(true);
      // if (!isAuthorized) {
      //   history.push('/');
      // } else {
      //   setIsAuthorized(true);
      // }
    }

    checkRoles(options.roles);
  }, [getIdTokenClaims, history]);

  return isAuthorized ? <Component /> : <></>;
}
