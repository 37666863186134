import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const regex = new RegExp(/^[A-Z]{3}[0-9]{2}[A-HJ-NPR-UW-Z0-9]{1}$/i);
const regexInput = '[a-zA-Z]{3}[0-9]{2}[a-hj-npr-uw-zA-HJ-NPR-UW-Z0-9]{1}';

export function RegNumberInput({ value, onChange, disabled }) {
  const [isBadFormat, setIsBadFormat] = useState(false);

  function onChangeRegexp(e) {
    const val = e.target.value;
    onChange(e);
    if (regex.test(val)) {
      setIsBadFormat(false);
    }
    else {
      setIsBadFormat(true);
    }
  }

  return (
    <FormGroup>
      <Label htmlFor="carRegNo">Registreringsnummer</Label>
      <Input type="text" name="carRegNo" id="carRegNo" onChange={onChangeRegexp} value={value?.toUpperCase()} pattern={regexInput} invalid={isBadFormat && !(value === '')} valid={!isBadFormat && !(value === '')} disabled={disabled} required />
      <FormFeedback>Fel format - ABC123</FormFeedback>
    </FormGroup>
  );
}