import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useRequest from '../../api/Api';
import InstallerService from '../../api/services/InstallerService';
import UpdateInstallerForm from '../../components/installer/UpdateInstallerForm';
import { validate as uuidValidate } from 'uuid';

const InstallerEdit = () => {
  const request = useRequest();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const stateId = location?.state?.id ?? '';

  const [installer, setInstaller] = useState();


  useEffect(() => {
    const getInstaller = async (id) => {
      if (!uuidValidate(id) || id !== stateId)
        return history.push('/not-found');

      const inst = await fetchInstaller(id);
      if (inst)
        setInstaller(inst);
    }

    getInstaller(id);
  }, [id])

  const fetchInstaller = async (id) => {
    return await request(InstallerService.getById(id));
  }

  const updateInstaller = async (updateInstaller) => {
    const updatedInstaller = await request(InstallerService.update(installer.id, updateInstaller));
    if (updateInstaller)
      history.goBack();
  }

  return (
    <div>
      <h2>Uppdatera montören</h2>
      {installer &&
        <UpdateInstallerForm
          onUpdate={updateInstaller}
          installerToUpdate={installer}
        />
      }
    </div>
  )
}

export default InstallerEdit;
