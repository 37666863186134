import React from 'react'
import { Card, CardTitle, CardText } from 'reactstrap';

const DashboardCard = ({ title, text, textClassName, color, inverse }) => {
  return (
    <Card body className="text-center" inverse={inverse} style={{ backgroundColor: color, borderColor: color }}>
      <CardTitle tag="h1">{title}</CardTitle>
      <CardText className={textClassName}>{text}</CardText>
    </Card>
  )
}

export default DashboardCard;
