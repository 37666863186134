import React from 'react';
import QrReader from 'modern-react-qr-reader';

export function QrScanner({ onScan }) {
  const delay = 300;
  const previewStyle = {
    // height: 100,
    width: 320 // '100%'
  };

  const handleScan = (result) => {
    if (result) {
      const splitUrl = result.split('/');
      const id = splitUrl[splitUrl?.length - 1];
      onScan(id);
    }
  }

  const handleError = (err) => {
    console.error(err);
  }

  return (
    <div>
      <QrReader delay={delay} style={previewStyle} onScan={handleScan} onError={handleError} />
    </div>
  );
}