import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react';
import useRequest from '../../api/Api';
import UnitService from '../../api/services/UnitService';
import CustomerService from '../../api/services/CustomerService';
import InstallerService from '../../api/services/InstallerService';
import { useDebounce } from 'react-use';
import { UnitForm } from '../../components/unit/UnitForm';
import { ValidateForm } from '../../components/unit/ValidateForm';
import { AddUnitForm } from '../../components/unit/AddUnitForm';
import { UnitSuccess } from '../../components/unit/UnitSuccess';
import { QrScanner } from '../../components/common/QrScanner';
import IdGenerator from '../../components/common/IdGenerator';
import QrGenerator from '../../components/common/QrGenerator';
import { CustomerFormFields } from '../../components/customer/CustomerFormFields';
import Search from '../../components/customer/Search';
import LiquidPartService from '../../api/services/LiquidPartService';

export function ReplaceUnit() {
  const request = useRequest();
  const { user } = useAuth0();

  const initialUnit = {
    'id': '',
    'carRegNo': '',
    'carMileage': 0,
    'carMileageAtInstall': 0,
    'mileageTravelled': 0,
    'mileagePerYear': 0,
    'customerId': ''
  };

  const initialCustomer = {
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  const [id, setId] = useState('');
  const [unit, setUnit] = useState(initialUnit);
  const [oldId, setOldId] = useState();
  const [isActive, setIsActive] = useState(true);
  const [unitExists, setUnitExists] = useState(true);
  const [step, setStep] = useState(1);
  const [customer, setCustomer] = useState(initialCustomer);
  const [customerModified, setCustomerModified] = useState(false);

  const [type, setType] = useState(null);
  const [liquidPartId, setLiquidPartId] = useState();

  const [debouncedId, setDebouncedId] = useState('');
  const [, cancel] = useDebounce(
    () => {
      setDebouncedId(id);
    },
    500,
    [id]
  );

  useEffect(() => {
    if (id && id?.length === 36)
      validate();
  }, [debouncedId]);

  const next = () => setStep(step + 1);

  // Add unit
  const addUnit = async (installerId, customerId) => {
    const newUnit = { ...unit, installerId: installerId, customerId: customerId };
    return await request(UnitService.create(newUnit));
  }

  // Disable unit
  const disableUnit = async () => {
    return await request(UnitService.disable(oldId));
  }

  // Fetch installer
  const getInstaller = async () => {
    return await request(InstallerService.getByProviderId(user.sub));
  }

  // Add installer
  const addInstaller = async () => {
    return await request(InstallerService.create({ providerUserId: user.sub, name: user.nickname, email: user.email }));
  }

  // Update customer
  const updateCustomer = async () => {
    if (customer.id) {
      if (customerModified)
        return await request(CustomerService.update(customer.id, customer));

      return customer;
    }
  }

  // Add customer
  const addCustomer = async () => {
    return await request(CustomerService.create(customer));
  }

  // Handle forms/components
  const handleIdChange = (id) => {
    setId(id);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUnit({ ...unit, [name]: value });

    if (!isActive)
      setIsActive(!isActive);
    if (!unitExists)
      setUnitExists(!unitExists);
  }

  const validate = async () => {
    if (step === 1) {
      let currentType = '';
      const liquidPart = await request(LiquidPartService.getById(id)).catch(() => null);
      if (liquidPart && liquidPart.deletedDate === null) {
        currentType = 'liquid';
      }
      else
        currentType = 'unit';

      const validUnit = await request(UnitService.getById(currentType === 'liquid' ? liquidPart?.unitId : id))
        .catch(() => setUnitExists(false));

      if (validUnit) {
        setUnit(validUnit);
        setLiquidPartId(liquidPart?.id ?? validUnit.liquidPart?.id);
        setType(currentType);

        if (validUnit.customer)
          setCustomer(validUnit.customer);

        setIsActive(validUnit.active);
        if (validUnit.active)
          next();
      }
    }
    else if (step === 3) {
      let validate;
      if (type === 'liquid')
        validate = await request(LiquidPartService.getById(id)).catch(() => null);
      else
        validate = await request(UnitService.validate(id));


      if (validate)
        setUnitExists(validate)
      else {
        type === 'liquid' ? setLiquidPartId(id) : setUnit({ ...unit, id: id });
        next();
      }
    }
  }


  // Old unit
  const handleSubmitOldUnit = (e) => {
    e.preventDefault();
    setOldId(id);
    setId('');
    setUnit({ ...unit, carMileageAtInstall: unit.carMileage });
    setUnitExists(false);
    next();
  }


  // New unit
  const handleAddUnitSubmit = async (e) => {
    e.preventDefault();
    if (user === null)
      return;

    // Get or Add installer
    let installer = await getInstaller();
    if (!installer)
      installer = await addInstaller();

    if (type === 'liquid') {
      console.log(unit)
      await request(UnitService.update(unit.id, { ...unit }));

      await disableLiquidPart(installer.id);
      const newliquidPart = await createLiquidPart(unit.id, installer.id);
      if (newliquidPart)
        next();
    }
    else {
      // Disable unit
      const unitDisabled = await disableUnit();
      if (unitDisabled) {
        console.log(`Disabled ${unitDisabled.id}`);

        // Update or Add customer
        let customer = await updateCustomer();
        if (!customer)
          customer = await addCustomer();

        const newUnit = await addUnit(installer.id, customer.id);
        if (newUnit) {
          await updateLiquidPart(newUnit.id);
          next();
        }
      }
    }
  }

  // Customer
  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });

    if (!customerModified)
      setCustomerModified(true);
  }

  // Searchbar
  const handleSearchOptions = async (inputValue) => {
    return request(CustomerService.getByName(inputValue)).then(c => c.items);
  };

  // LiquidPart
  const createLiquidPart = async (unitId, installerId) => {
    return await request(LiquidPartService.create({ id: liquidPartId, unitId: unitId, installerId: installerId }));
  }

  const disableLiquidPart = async (installerId) => {
    return await request(LiquidPartService.remove(oldId, { id: oldId, installerId: installerId }));
  }

  const updateLiquidPart = async (unitId) => {
    return await request(LiquidPartService.update(liquidPartId, { id: liquidPartId, unitId: unitId }));
  }

  return (
    <div>
      <h2>Byt enhet</h2>
      {step === 1 && (
        <div>
          <h4>Nuvarande elektronikbox eller vätskebehållare</h4>
          <p>Skanna QR-koden eller fyll i id för elektronikbox eller vätskebehållare som ska bytas ut</p>
          <QrScanner onScan={handleIdChange} />
          <ValidateForm className="mt-4" id={id} onChange={(e) => handleIdChange(e.target.value)} onValidate={validate} invalid={!isActive || !unitExists} feedback={!isActive && 'Enheten är släckt' || !unitExists && 'Enheten finns ej'} />
        </div>
      )}
      {step === 2 && (
        <div>
          <p>Bekräfta enhetens och fordonets uppgifter.</p>
          <UnitForm unit={unit} onChange={handleChange} onSubmit={handleSubmitOldUnit} buttonColor="primary">
            <hr className="mt-5 mb-4" />
            <h5>Kunduppgifter</h5>
            <p className="mb-4">Uppdatera kunduppgifterna eller välj en annan kund genom sökfältet.</p>
            <Search
              initialValue={[customer, initialCustomer]}
              selectedValue={customer}
              onSelect={(c) => setCustomer(c)}
              loadOptions={handleSearchOptions}
            />
            <CustomerFormFields customer={customer} onChange={handleCustomerChange} />
          </UnitForm>
        </div>
      )}
      {step === 3 && (
        <div>
          <h4>Ny {type === 'liquid' ? 'vätskebehållare' : 'elektronikbox'}</h4>
          <p>Skanna QR-koden eller fyll i id för den nya {type === 'liquid' ? 'vätskebehållare' : 'elektronikbox'}</p>
          <QrScanner onScan={handleIdChange} />
          <div className="my-3">
            <IdGenerator onGenerate={handleIdChange} />
          </div>
          <ValidateForm id={id} onChange={(e) => handleIdChange(e.target.value)} onValidate={validate} invalid={unitExists} feedback={unitExists && 'Enheten finns redan'} />
        </div>
      )}
      {step === 4 && (
        <div>
          <p>Fyll i uppgifter om fordonet till den nya enheten.</p>
          <AddUnitForm unit={unit} liquidPartId={liquidPartId} onChange={handleChange} onSubmit={handleAddUnitSubmit} />
        </div>
      )}
      {step === 5 && (
        <div>
          <UnitSuccess />
          <QrGenerator value={unit.id} />
        </div>
      )}
    </div>
  );
}
