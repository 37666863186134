
const url = '/installer';

const getAll = (pageNumber = 1, pageSize = 10) => ({ method: 'get', url: `${url}?pageNumber=${pageNumber}&pageSize=${pageSize}` });

const getById = (id) => ({ method: 'get', url: `${url}/${id}` });

const getByProviderId = (providerUserId) => ({ method: 'get', url: `${url}/provider/${providerUserId}` });

const create = (installer) => ({ method: 'post', url: url, data: installer });

const update = (id, installer) => ({ method: 'patch', url: `${url}/${id}`, data: installer });

const sync = () => ({ method: 'get', url: `${url}/sync` });

const toggleBlocked = (id) => ({ method: 'delete', url: `${url}/${id}/block` });


export default {
  getAll,
  getById,
  getByProviderId,
  create,
  update,
  sync,
  toggleBlocked,
};