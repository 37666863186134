import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Button, CardDeck, Container, Form, Input, Label } from 'reactstrap';
import useRequest from '../../api/Api';
import UnitService from '../../api/services/UnitService';
import { format } from 'date-fns';
import sv from 'date-fns/locale/sv';
import DashboardCard from '../../components/dashboard/DashboardCard';

const Unit = () => {
  const { id } = useParams();
  const history = useHistory();
  const request = useRequest();

  const [unit, setUnit] = useState();
  const [customer, setCustomer] = useState('');
  const [mileage, setMileage] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    const getUnit = async (id) => {
      var u = await fetchUnit(id)
        .catch(err => console.error(err));
      console.log(u)
      if (u)
        setUnit(u);

    }

    getUnit(id);
  }, [id])

  const fetchUnit = async (id) => {
    return await request(UnitService.getByIdNoAuth(id));
  }

  const validateEmail = async (id, email) => {
    return await request(UnitService.getByEmailNoAuth(id, email));
  }

  const updateMileage = async (id, unit) => {
    return await request(UnitService.updateVehicleMileage(id, unit));
  }


  const validateUser = async (e) => {
    e.preventDefault();
    console.log(customer);
    const valid = await validateEmail(id, customer)
      .catch(err => console.error(err));
    if (valid)
      setIsEmailValid(!isEmailValid)
    // history.push({ pathname: `/unit/${id}/edit`, state: { id: id, customer: customer } });
  }

  const updateUnit = async (e) => {
    e.preventDefault();
    const updData = { customerEmail: customer, carMileage: mileage }
    const updatedUnit = await updateMileage(id, updData);
    if (updatedUnit)
      setUnit(updatedUnit);
  }

  //d-lg-flex align-items-center justify-content-between flex-row flex-wrap
  return (
    <Container className="text-center d-flex flex-column justify-content-center min-vh-100">
      <h1 className="display-2">Emi<span className="font-weight-bold">Nique</span></h1>
      {unit && (
        <>
          <div className="h5 mt-5">
            <CardDeck className="flex-lg-row flex-column">
              <DashboardCard textClassName="text-muted" text={"Registreringsnummer"} title={unit?.carRegNo} /* color="#fff" */ />
              <DashboardCard textClassName="text-muted" text={"Senaste registrerade mätarställning (fordon)"} title={`${unit?.carMileage} mil`} /* color="#fff" */ />
            </CardDeck>
            <CardDeck className="flex-lg-row flex-column">
              <DashboardCard textClassName="text-muted" text={"Enhetens installationsdatum"} title={format(new Date(unit.installDate), 'yyyy-MM-dd', { locale: sv })} /* color="#fff" */ />
              <DashboardCard textClassName="text-muted" text={"Enhetens miltal"} title={`${unit?.mileageTravelled} mil`} /* color="#fff" */ />
              <DashboardCard textClassName="text-muted" text={"Beräknat datum för byte av Eminique"} title={format(new Date().setDate(new Date(unit.installDate).getDate() + (365)), 'yyyy-MM-dd', { locale: sv })} /* color="#fff" */ />
            </CardDeck>
          </div>

          {isEmailValid ? (
            <div className="mt-5">
              <p className="h4">Registrera mätarställning för ditt fordon</p>
              <Form inline onSubmit={updateUnit} className="justify-content-center">
                <Input type="number" min={0} name="mileage" id="mileage" placeholder="Miltal" onChange={(e) => setMileage(e.target.value)} value={mileage} required />
                <Button color="primary" className="my-3">Spara</Button>
              </Form>
            </div>
          ) : (
            <div className="mt-5">
              <p className="h4">Fyll i din e-post för att uppdatera Eminique</p>
              <Form inline onSubmit={validateUser} className="justify-content-center">
                <Input type="email" name="customer" id="customer" placeholder="E-post" onChange={(e) => setCustomer(e.target.value)} value={customer} required />
                <Button color="primary" className="my-3">Gå vidare</Button>
              </Form>
            </div>
          )}
        </>
      )
      }
    </Container >
  )
}

export default Unit;
