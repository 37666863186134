import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import useRequest from '../../api/Api';
import CustomerService from '../../api/services/CustomerService';

import CustomerForm from '../../components/customer/CustomerForm';


const CustomerEdit = () => {
  const request = useRequest();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const stateId = location?.state?.id ?? '';

  const initialCustomer = {
    'name': '',
    'email': '',
    'phone': '',
    'address': '',
    'zip': '',
    'city': ''
  };

  const [customer, setCustomer] = useState(initialCustomer);

  useEffect(() => {
    const getCustomer = async () => {
      // Check that its the correct guid
      if (!uuidValidate(id) || id !== stateId)
        return history.push('/not-found');

      const customerData = await fetchCustomer(id);
      if (customerData)
        setCustomer(customerData);
    }
    getCustomer();
  }, [id])


  const fetchCustomer = async (id) => {
    return await request(CustomerService.getById(id));
  }


  const updateCustomer = async () => {
    const updatedCustomer = await request(CustomerService.update(id, customer));
    if (updatedCustomer)
      history.goBack();
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer({ ...customer, [name]: value });
  }

  return (
    <div>
      <h2>Kunduppgifter</h2>
      {customer &&
        <CustomerForm
          onSubmit={updateCustomer}
          customer={customer}
          onChange={handleChange}
          buttonText={'Spara'}
        // useBackButton={true}
        />
      }
    </div>
  )
}

export default CustomerEdit;
