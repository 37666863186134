import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import config from './config';

import registerServiceWorker from './registerServiceWorker';
import App from './App';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  history.replace(appState?.returnTo || window.location.pathname);
};

ReactDOM.render(
  <Auth0Provider
    domain={config.AUTH_DOMAIN}
    clientId={config.AUTH_CLIENT_ID}
    audience={config.AUTH_AUDIENCE}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <Router basename={baseUrl} history={history}>
      <App />
    </Router>
  </Auth0Provider >,
  rootElement);

registerServiceWorker();

