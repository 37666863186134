import React, { useState, useEffect } from 'react'
import useRequest from '../../api/Api';
import CustomerService from '../../api/services/CustomerService';

import { Table, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Pagination from 'react-responsive-pagination';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const tableHeaders = [
  { id: 'name', name: 'Namn' },
  { id: 'email', name: 'E-post' },
  { id: 'phone', name: 'Tele' },
  { id: 'address', name: 'Adress' },
  { id: 'city', name: 'Stad' },
  { id: 'edit', name: '' },
  { id: 'delete', name: '' },
]

const CustomerList = () => {
  const request = useRequest();
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [delCustomer, setDelCustomer] = useState(null);
  const [modal, setModal] = useState();


  useEffect(() => {
    const getCustomers = async () => {
      setLoading(true);

      const data = await fetchCustomers(currentPage);

      setCustomers(data.items);
      setCurrentPage(data.currentPage);
      setTotalPages(data.totalPages);

      setLoading(false);
    }

    getCustomers();

  }, [currentPage])


  const fetchCustomers = async (pageNumber) => {
    return await request(CustomerService.getAll(pageNumber));
  }

  // Redirect user to customer editing page
  const editCustomer = async (id) => {
    history.push({ pathname: `/customers/edit/${id}`, state: { id: id } });
  }

  const deleteCustomer = async (id) => {
    await request(CustomerService.deleteById(id)).catch(() => alert('Error, gick inte att ta bort kunden'));
    setCustomers(customers.filter(customer => customer.id !== id));
  }

  // Pagination page click
  const pageClick = (page) => {
    if (!loading)
      setCurrentPage(page);
  }

  const toggle = () => setModal(!modal);

  return (
    <div>
      <h2>Kunder</h2>
      <Table responsive striped>
        <thead>
          <tr>
            {
              tableHeaders && tableHeaders.map(header => (
                header.name
                  ? <th key={header.id} id={header.id}>{header.name}</th >
                  : <th key={header.id} id={header.id} ></th>
              ))
            }
          </tr >
        </thead >
        <tbody>
          {customers && customers.map(customer => (
            <tr key={customer.id}>
              <td>{customer.name}</td>
              <td>{customer.email}</td>
              <td>{customer.phone}</td>
              <td>{customer.address}</td>
              <td>{customer.city + " " + customer.zip}</td>
              <td>
                <Button outline color="primary" size="sm" style={{ marginTop: '-0.3rem' }} onClick={() => editCustomer(customer.id)}>Ändra</Button>
              </td>
              <td>
                <Button outline color="danger" size="sm" style={{ marginTop: '-0.3rem' }} onClick={() => { setDelCustomer(customer); toggle(); }}>Ta bort</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table >

      {delCustomer && modal && (
        <Modal isOpen={modal} toggle={toggle} backdrop={false} >
          <ModalHeader>Ta bort</ModalHeader>
          <ModalBody >
            Är du säker på att du vill ta bort {delCustomer.name}?
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={() => { deleteCustomer(delCustomer.id); toggle(); }}>Ta bort</Button>
            <Button onClick={toggle}>Avbryt</Button>
          </ModalFooter>
        </Modal>

      )}

      <div>
        <Pagination
          current={currentPage}
          total={totalPages}
          onPageChange={pageClick}
        />
      </div>
    </div >
  );
}

export default CustomerList;
