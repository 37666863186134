import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function CustomerFormFields({ customer, onChange, disableAll }) {
  // const onSubmit = (e) => {
  //   e.preventDefault();
  // }
  // const onChange = (e) => {
  //   const { name, value } = e.target;
  //   handleChange(name, value);
  // };

  return (
    // <Form onSubmit={onSubmit}>
    <>
      <FormGroup>
        <Label htmlFor="name">Namn</Label>
        <Input type="text" name="name" id="name" onChange={onChange} value={customer.name} required disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="email">E-post</Label>
        <Input type="email" name="email" id="email" onChange={onChange} value={customer.email} required disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="phone">Telefon</Label>
        <Input type="tel" name="phone" id="phone" onChange={onChange} value={customer.phone} required disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="address">Adress</Label>
        <Input type="text" name="address" id="address" onChange={onChange} value={customer.address} required disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="zip">Postnummer</Label>
        <Input type="text" name="zip" id="zip" onChange={onChange} value={customer.zip} required disabled={disableAll} />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="city">Postort</Label>
        <Input type="text" name="city" id="city" onChange={onChange} value={customer.city} required disabled={disableAll} />
      </FormGroup>
    </>
    // </Form>
  );
}