import React, { useState } from 'react'
import { Form, FormGroup, Input, Label, Button, FormFeedback } from 'reactstrap'
import validator from 'validator';


const AddInstallerForm = ({ onAdd }) => {
  const initialInstaller = {
    email: '',
    password: '',
    name: '',
    phone: '',
  };

  const [installer, setInstaller] = useState(initialInstaller);
  const [passwordError, setPasswordError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    // Check that password meets the requirements
    var passwordReq = { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, };
    if (!validatePassword(installer.password, passwordReq)) {
      setPasswordError(true);
      return;
    }

    onAdd(installer);
  }

  // Update installer state
  const onChange = (e) => {
    const { name, value } = e.target;
    setInstaller({ ...installer, [name]: value });
  }

  const validatePassword = (password) => {
    var passwordReq = {
      minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1,
    }

    return (validator.isStrongPassword(password, passwordReq))
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Label htmlFor="email">E-post</Label>
        <Input type="email" name="email" id="email" onChange={onChange} value={installer.email} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="password">Lösenord</Label>
        <Input type="password" name="password" id="password" onChange={onChange} value={installer.password} required invalid={passwordError} />
        <FormFeedback>
          <ul>
            <li>Specialtecken (!@#$%^&*)</li>
            <li>Gemener (a-z), versaler (A-Z) och siffror (0-9)</li>
            <li>Minst 8 tecken</li>
          </ul>
        </FormFeedback>
      </FormGroup>
      <hr />
      <FormGroup>
        <Label htmlFor="name">Namn</Label>
        <Input type="text" name="name" id="name" onChange={onChange} value={installer.name} required />
      </FormGroup>
      <FormGroup>
        <Label htmlFor="phone">Telefon</Label>
        <Input type="text" name="phone" id="phone" onChange={onChange} value={installer.phone} required />
      </FormGroup>
      <Button color="primary">Spara</Button>
    </Form>
  )
}

export default AddInstallerForm;
