import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';

export default function LogoutButton() {
  const { logout } = useAuth0();

  return (
    <NavLink tag={Link} className="text-dark" to='' onClick={() => logout({ returnTo: window.location.origin })}>Logga ut</NavLink>
  );
}