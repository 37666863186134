const url = '/liquidpart';

const getById = (id) => ({ method: 'get', url: `${url}/${id}` });

const create = (liquidPart) => ({ method: 'post', url: url, data: liquidPart });

const update = (id, liquidPart) =>  ({ method: 'put', url: `${url}/${id}`, data: liquidPart });

const remove = (id, liquidPart) => ({ method: 'delete', url: `${url}/${id}`, data: liquidPart });

export default {
  getById,
  create,
  update,
  remove,
};