import React from 'react'
import { useHistory } from 'react-router-dom';
import useRequest from '../../api/Api';
import InstallerService from '../../api/services/InstallerService';
import AddInstallerForm from '../../components/installer/AddInstallerForm';

const InstallerAdd = () => {
  const request = useRequest();
  const history = useHistory();


  const addInstaller = async (installer) => {
    await request(InstallerService.create(installer));
    history.goBack();
  }

  return (
    <div>
      <h2>Lägg till montör</h2>
      <AddInstallerForm onAdd={addInstaller} />
    </div>
  )
}

export default InstallerAdd;
