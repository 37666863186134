
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { FormGroup, Label } from 'reactstrap';

export default function Search({ initialValue, onSelect, loadOptions }) {
  const [value, setValue] = useState(Array.isArray(initialValue) ? initialValue[0] : initialValue);
  const [defaultValue] = useState(initialValue ? (Array.isArray(initialValue) ? [...initialValue] : [initialValue]) : '');

  // handle selection
  const handleChange = value => {
    setValue(value);
    onSelect(value);
  }

  return (
    // <div>
    // {/* <pre>Input Value: '{inputValue}'</pre> */}
    <FormGroup>
      <Label htmlFor='search'>Sök efter kund</Label>
      <AsyncSelect
        id='search'
        name='search'
        cacheOptions
        defaultOptions={defaultValue}
        value={value}
        getOptionLabel={e => e.id ? e.name + ', ' + e.email + ', ' + e.address + ', ' : 'Ny kund'}
        getOptionValue={e => e.name}
        loadOptions={loadOptions}
        onChange={handleChange}
        placeholder='Sök...'
      />
    </FormGroup>
    // {/* <pre>Selected Value: {JSON.stringify(value || {}, null, 2)}</pre> */}
    // </div>
  );
}